<template>
<div class="contai-wrapper">
  <div class="container">
    <img src="/icons/404@2x.png" alt="" class="logo mb24">
    <p class="text mb12">网络无法连接，请点击刷新</p>
    <img src="/images/qrcode2.jpg" alt="" class="qrcode mb24">

    <a href="javascript:;" class="download-btn  mb24">返回首页</a>

    <p class="text mb34">问题反馈请前往“青麦QIMAI”公众号</p>
    <div class="wx-wrapper">
      <BaseWx />
    </div>
  </div>
</div>

</template>
<script>
import BaseWx from '@/components/base-wx'

export default {
  components:{
    BaseWx
  },
  data(){
    return{

    }
  }
}
</script>
<style scoped>
.contai-wrapper{
  width:100%;
  height:calc( 100vh - 70px );
  background-color: #FFF;
}

.container{
  width:300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top:34px;
  margin:0 auto;
}

.logo{
  width: 266.89px;
  height: 200px;
}

.mb24{
  margin-bottom:24px;
}

.mb34{
  margin-bottom:34px;
}

.mb12{
  margin-bottom:12px;
}

.text{
  color:#303030;
  font-size: 14px;
}

.fwb{
  font-weight: bold;
}

.download-btn{
  display: block;
  text-decoration: none;
  text-align: center;
  width: 98px;
  height: 35px;
  line-height: 35px;
  border-radius: 18px;
  background-color: #04cb94;
  font-size: 14px;
  color:#FFF;
  margin-bottom: 26px;
}

.index{
  width:314px;
}

.wx-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}


</style>