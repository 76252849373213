import VueRouter from 'vue-router'
import notFoundPage from '@/views/404'

const routes=[
  {
    path:'/',
    component:()=>import('@/views/index.vue'),
    meta:{
      title:'',
    }
  },
  {
    path:'/index',
    component:()=>import('@/views/index.vue'),
    meta:{
      title:''
    }
  },
  {
    path:'/select',
    component:()=>import('@/views/select'),
    meta:{
      title:'选择影片'
    }
  },
  {
    name:'select',
    path:'/select/:tpye',
    component:()=>import('@/views/select.vue'),
    meta:{
      title:'选择影片'
    }
  },
  {
    path:'/search/',
    component:()=>import('@/views/search.vue'),
    meta:{
      title:'影片搜索'
    }
  },
  {
    name:'search',
    path:'/search/:keyword',
    component:()=>import('@/views/search.vue'),
    meta:{
      title:'影片搜索'
    }
  },
  {
    path:'/history',
    component:()=>import('@/views/history'),
    meta:{
      title:'历史记录'
    }
  },
  {
    path:'/app-download',
    component:()=>import('@/views/app-download'),
    meta:{
      title:'APP下载'
    }
  },
  {
    path:'/support',
    component:()=>import('@/views/support'),
    meta:{
      title:'赞助青麦'
    }
  },
  {
    path:'/copyright',
    component:()=>import('@/views/copyright'),
    meta:{
      title:'侵权处理'
    }
  },
  {
    path:'/friendlink',
    component:()=>import('@/views/friendlink'),
    meta:{
      title:'友情链接'
    }
  },
  // 404页面不能懒加载
  {
    path:'/404',
    component:notFoundPage,
    meta:{
      title:'404'
    }
  },
  {
    path:'*',
    component:notFoundPage,
    meta:{
      title:'404'
    }
  }
]


// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default new VueRouter({
  routes
})