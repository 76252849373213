<template>

  <div class="gg-wrapper-rb" v-show="show && afCfg">
    <span class="close" @click="show=false" v-show="closeBtnShow">关闭</span>
    <a :href="afCfg.pcUrl || afCfg.wapUrl" target="_blank">
      <img :src="afCfg.pcImg" alt="">
    </a>
  </div>
</template>
<script>
import {mapState } from 'vuex'

export default {
  props:{
    width:{
      type:String,
      default:'100%'
    },
    height:{
      type:String,
      default:'200px'
    },
    name:{
      type:String,
      default:''
    }
  },
  data(){
    return {
      show:true,
      closeBtnShow:false
    }
  },
  computed:{
    afCfg(){
      let findObj=this.floatAfs.find((item)=>{
        return item.name===this.name
      })
      if (findObj){
        return findObj
      }else{
        return false
      }
    },
    ...mapState({
      floatAfs:state=>state.af.floatAfs,
      webUrl:state=>state.setting.webUrl
    })
  },
  mounted(){
    setTimeout(()=>{
      this.closeBtnShow=true
    },3000)
  }
}
</script>
<style scoped>
/* css不能出现ad字样 会被广告插件屏蔽 */

.gg-wrapper-rb{
  position: fixed;
  width:400px;
  height:400px;
  right:0px;
  bottom:0px;
  background-color: #D3D3D3;
  font-size: 40px;
  color:#FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
}

.gg-wrapper-rb .close{
  position: absolute;
  top:0px;
  left: 0px;
  color:#04CB94;
  font-size: 14px;
  cursor: pointer;
  text-shadow:0 1px #FFF,1px 0 #FFF,-1px 0 #FFF,0px -1px #FFF;
  font-weight: bold;
}

.gg-wrapper-rb img{
  width:100%;
  height:100%;
  display: block;
}


</style>