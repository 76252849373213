import {detailTripartite} from '@/api/tripartite'
import {detailDscount} from '@/api/dscount'
import {detailSponsor} from '@/api/sponsor'
import {detailWebsite} from '@/api/website'
import {insertDom} from '@/utils/index'

export default {
  namespaced:true,
  state:{
    // 网站收录
    webUrl:'',
    // 广告合作
    adUrl:'',
    // 帮助反馈
    helpUrl:'',
    dscountImg:'',
    dscountUrl:'',
    webLogo:'',
    webTitle:'',
    versionContent:'',
    sponsorDetailsImg:'',
    sponsorImgUrl:'',
    sponsorIndexImg:'',
    sponsorContent:''
  },
  mutations:{
    // 三方网站
    SET_Tripartite(state,res){
      state.webUrl=res.webUrl
      state.adUrl=res.adUrl
      state.helpUrl=res.helpUrl
    },
    // 青麦惠购
    SET_Dscount(state,res){
      state.dscountImg=res.indexImg
      state.dscountUrl=res.imgUrl
    },
    // 网站信息
    SET_WEBINFO(state,res){
      state.webLogo=res.logo
      state.webTitle=res.seoTitle
      state.versionContent=res.versionContent
    },
    // 赞助青麦
    SET_Sponsor(state,res){
      state.sponsorDetailsImg=res.detailsImg
      state.sponsorIndexImg=res.indexImg
      state.sponsorImgUrl=res.imgUrl
      state.sponsorContent=res.content
    }
  },
  actions:{
    sync({commit}){
      detailTripartite().then((res)=>{
        res=res.data
        commit('SET_Tripartite',res)
      })
      detailDscount().then((res)=>{
        res=res.data
        commit('SET_Dscount',res)
      })
      detailSponsor().then((res)=>{
        res=res.data
        commit('SET_Sponsor',res)
      })
      detailWebsite().then((res)=>{
        res=res.data
        commit('SET_WEBINFO',res)

        insertDom('link',document.head,{
          rel:'icon',
          href:res.icon
        })

        insertDom('meta',document.head,{
          rel:'keywords',
          content:res.seoKeyWord
        })

        insertDom('meta',document.head,{
          rel:'description',
          content:res.seoDescribe
        })

        
      })
    }
  }
}