import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import {Carousel,CarouselItem,Image,infiniteScroll,Icon,Notification, Message,Loading } from 'element-ui'
import router from '@/router/index'
import store from '@/store/index'

Vue.use(VueRouter)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Image)
Vue.use(infiniteScroll)
Vue.use(Icon)
Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;

Vue.config.productionTip = false

router.beforeEach((to,from,next)=>{
  if (to.meta.title){
    document.title=to.meta.title
  }

  next()
})

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
