<template>
  <div class="search-wrapper" ref="searchWrapper">
    <div class="search-inner">
      <input 
        ref="searchInput" 
        :value="searchText"
        type="search" 
        class="search" 
        @focus="inputHandle($event.target.value)" 
        @keypress.enter="searchHandle($event.target.value)" 
        @input="inputHandle($event.target.value)"
        placeholder="剧集 / 电影 / 动漫/ 综艺..."
      >
      <img src="/icons/search-ico@2x.png" alt="" class="search-ico" @click="icoClickHandle">

      <img src="/icons/close2-ico@2x.png" alt="" class="search-clear" v-show="searchText" @click="searchCancelHandle">

    </div>
    <!--focus 弹出-->
    <div class="search-res-blank" v-if="resBlankShow">
      <div class="title-wrapper">
        <span class="black">热门搜索</span>
        <span class="green pointer" @click="resBlankShow=false">取消搜索</span>
      </div>
      <div class="search-res">
        <a 
          class="res-item" 
          href="javascript:;" 
          v-for="(item) of resBlank" 
          :key="item.title"
          @click="searchHandle(item.title)"
        >
          <span class="no">{{parseInt(item.sort)}}</span>
          <span class="name">{{item.title}}</span>
          <span class="hot">
            <img src="/icons/hot-ico@2x.png" alt="" class="ico">
            {{item.searchVolume}}
          </span>
        </a>
      </div>
      <div class="title-wrapper">
        <span class="black">搜索历史</span>
        <span class="black pointer" @click="clearSearchHistory">
          <img src="/icons/del-ico@2x.png" alt="" class="ico">
          清空
        </span>
      </div>
      <div class="search-history">
        <a @click="searchHandle(item.keyword)" href="javascript:;" class="item" v-for="(item) of searchHistory" :key="item.keyword">{{item.keyword}}</a>

      </div>
    </div>
    <!--input 弹出-->
    <div class="search-input-res" v-if="resShow">
      <a @click="searchHandle(item.sourceText)" class="item" href="javascript:;" v-html="item.htmlText" v-for="(item,index) of searchResult" :key="index"></a>
    </div>
  </div>
</template>
<script>
import {searchMovieTips,searchMovieHot} from '@/api/movie-index'

export default {
  data(){
    return {
      searchText:'',
      resBlank:[
      ],
      resBlankShow:false,
      resShow:false,
      searchResult:[]
    }
  },
  computed:{
    searchHistory(){
      return this.$store.getters['search/sortRecords'].slice(0,10)
    },
    searchKeyword(){
      return this.$store.state.search.keyword
    }
  },
  watch:{
    searchKeyword(val){
      this.searchText=val
    }
  },
  methods:{
    // 根据文本框文字数量相应弹窗
    inputHandle(searchText){
      this.searchText=searchText
      if (searchText){  
        this.resBlankShow=false
        this.searchWidthText(searchText)
      }else{
        this.resBlankShow=true
        this.resShow=false
      }
    },
    // 清空文字
    searchCancelHandle(){
      this.searchText=''
      this.$store.dispatch('search/setKeyword','')
      this.resShow=false
      this.resBlankShow=true

      this.$router.push({
        path:'/search'
      })
      
      this.$nextTick(()=>{
        this.$refs['searchInput'].focus()
      })
    },
    // 有文字的情况下搜索
    searchWidthText(searchText){
      searchMovieTips({
        name:searchText
      }).then((res)=>{
        this.searchResult=res.data.map((item)=>{
          let splitStart=item.indexOf(searchText)
          let splitEnd=splitStart+searchText.length

          let htmlText=item.substring(0,splitStart)+'<span class="green">'+searchText+'</span>'+item.substring(splitEnd)

          return {
            sourceText:item,
            htmlText
          }
        })

        this.resShow=Boolean((this.searchResult.length!==0))


      })
    },
    // 空白处点击
    bodyClickHandle(e){
      this.$nextTick(()=>{
        if (this.$refs['searchWrapper'] && !this.$refs['searchWrapper'].contains(e.target)){
          if (this.resBlankShow===true){
            this.resBlankShow=false
          }

          if (this.resShow===true){
            this.resShow=false
          }
        }
      })
    },
    // 获取热门排行
    getSearchMovieHot(){
      searchMovieHot({
        condition:'',
        pageNum:1,
        pageSize:5
      }).then((res)=>{
        res=res.data
        this.resBlank=res.slice(0,5).map((item)=>{
          if (item.searchVolume>10000){
            item.searchVolume=(item.searchVolume/10000).toFixed(1)+'万'
          }
          
          return item
        })
      })
    },
    // 搜索
    searchHandle(keyword){
      if (keyword!==''){
        this.$store.dispatch('search/setKeyword',keyword)
        this.$router.push({
          name:'search',
          params:{
            keyword
          }
        })
      }
      this.resShow=false
      this.resBlankShow=false
    },
    clearSearchHistory(){
      this.$store.dispatch('search/clear')
    },
    // PC专用
    icoClickHandle(){
      this.searchHandle(this.searchText)
    }
  },
  mounted(){
    document.addEventListener('click',this.bodyClickHandle)
    this.getSearchMovieHot()
  },
  beforeDestroy(){
    document.removeEventListener('click',this.bodyClickHandle)
  }
}
</script>
<style scoped>
  .search-wrapper{
    width: 427px;
    height: 45px;
    position: relative;
  }

  .search-inner{
    position: absolute;
    z-index:993;
    width: 427px;
    height: 45px;
    border-radius: 23px;
    border:1px solid #EBEBEB;
    background-color: #FFF;
    display: flex;
    align-items: center;
  }

  .search{
    height:43px;
    line-height:43px;
    border:none;
    outline:none;
    margin-left:25px; 
    color:#474747;
    flex-grow:1;
  }

  .search::placeholder{
    color:#9E9E9E;
  }

  .search::-webkit-search-cancel-button{
    display: none;
  }

  .search-ico{
    width:20px;
    height:20px;
    margin-right:20px;
    flex-shrink: 1;
    cursor: pointer;
  }

  .search-clear{
    width:12px;
    height:12px;
    position: absolute;
    top:50%;
    margin-top: -6px;
    right:50px;
    cursor:pointer
  }

  .search-res-blank{
    width:100%;
    position: absolute;
    top:23px;
    left: 0px;
    z-index:992;
    background-color: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding:0px 14px;
    border-radius: 0px 0px 8px 8px;
    padding-top:24px;
  }

  .search-res-blank .title-wrapper{
    display: flex;
    height:50px;
    justify-content: space-between;
    align-items: center;
  }

  .search-res-blank .title-wrapper .black{
    color:#303030;
  }

  .search-res-blank .title-wrapper .black .ico{
    width:18px;
    height:18px;
    margin-right:4px;
    vertical-align: top;
  }
  
  .search-res-blank .title-wrapper .green{
    color:#23D1A1;
  }

  .search-res .res-item{
    display: flex;
    margin-bottom:11px;
  }

  .search-res{
    width:100%;
  }

  .search-res .res-item{
    display: flex;
    text-decoration: none;
    
  }

  .search-res .res-item:nth-of-type(1) .no{
    color:#FA5151;
  }

  .search-res .res-item:nth-of-type(2) .no{
    color:#FF8F1F;
  }

  .search-res .res-item:nth-of-type(3) .no{
    color:#FFC300;
  }

  .search-res .res-item .no{
    font-weight: bold;
    margin-right:11px;
    color:#9E9E9E;
  }

  .search-res .res-item .name{
    color:#303030;
  }

  .search-res .res-item .hot{
    color:#FCA338;
    margin-left:auto;
    display: flex;
    align-items: center;
  }

  .search-res .res-item .hot .ico{
    width:16px;
    height:16px;
    margin-right:4px;
  }

  .search-history{
    display: flex;
    flex-wrap: wrap;
  }

  .search-history .item{
    height:25px;
    line-height:25px;
    padding:0px 12px;
    border-radius: 13px;
    background-color: #EDEEF0;
    color:#303030;
    margin-right:10px;
    margin-bottom:9px;
    text-decoration: none;
  }

  .search-input-res{
    width:100%;
    position: absolute;
    top:23px;
    left: 0px;
    z-index:992;
    background-color: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding:0px 14px;
    border-radius: 0px 0px 8px 8px;
    padding-top:24px;
  }

  .search-input-res .item{
    padding:6px 0px;
    font-size: 14px;
    color:#303030;
    display: block;
    text-decoration: none;
  }

</style>
<style>
  .green{
    color:#23D1A1;
  }
</style>