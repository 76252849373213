import request from '@/utils/request'

// 首页最下方接口
export function screenMovie(data){
  return request({
    headers:{
      repeatSubmit:false
    },
    url:'/app/movie/index/screenMovie',
    method:'post',
    data
  })
}

// 搜索关键字联想
export function searchMovieTips(params){
  return request({
    url:'/app/movie/index/searchMovieTips',
    method:'get',
    params
  })
}


// 根据关键字检索电影
export function searchMovieByName(data){
  return request({
    url:'/app/movie/index/searchMovieByName',
    method:'post',
    data
  })
}

// 搜索空白点击的热门排行

export function searchMovieHot(data){
  return request({
    headers:{
      repeatSubmit:false
    },
    url:'/app/movie/index/searchMovieHot',
    method:'post',
    data
  })
}

