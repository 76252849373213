<template>
  <div id="app">
    <BaseHeader />
    <div class="container-wrapper">
      <router-view></router-view>
    </div>

    <BaseAfFullscreen name="首页弹窗广告位" />
    <baseAfRb name="右下角悬浮广告位" />

  </div>
</template>

<script>
import BaseHeader from '@/components/base-header'

import BaseAfFullscreen from '@/components/base-af-fullscreen.vue'
import baseAfRb  from './components/base-af-rb'

export default {
  name: 'App',
  components: {
    BaseHeader,
    BaseAfFullscreen,
    baseAfRb
  },
  methods:{
    initApp(){
      this.$store.dispatch('history/sync')
      this.$store.dispatch('search/sync')
      this.$store.dispatch('setting/sync')
      this.$store.dispatch('af/sync')
    }
  },
  mounted(){
    console.log('App mounted')
    this.initApp()
    // this.$message('测试测试')
  },
  beforeDestroy(){
    console.log('App destory')
  }
}
</script>
<style>
body,html{
  margin:0px;
  padding:0px;
  font-family: -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif;
  font-size: 14px;
  color:#303030;
}

p{
  margin:0px;
  padding:0px;
}

#app{
  background-color:#F7F8FA ;
  width:100%;
  min-height:calc( 100vh );
}

.container-wrapper::-webkit-scrollbar{
  display: none;
}

.pointer{
  cursor: pointer;
}

.container-wrapper{
  width:100%;
  margin:0 auto;
  position: relative;
  padding-top:70px;
}



.hide{
  display: none !important;
}

.mt16{
  margin-top:16px;
}

.mb16{
  margin-bottom:16px;
}

.h15{
  height:15px;
}

.w100p{
  width:100%;
}




/* fade animate */
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fade-enter-to,.fade-leave{
  opacity: 1;
}


.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active for below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}
</style>

