<template>
  <div class="header-wrapper">
    <div class="header">
      <router-link to="/">
        <img :src="webLogo" alt="" class="logo">
      </router-link>

      <BaseSearch class="jz" />

      <div class="links">
        <a @click="appDownloadHandle" class="link" href="javascript:;" @mouseenter="appShow=true" @mouseleave="appShow=false">
          <img src="/icons/phone-ico@2x.png" alt="" class="ico">
          <span>APP</span>
        </a>
        <a class="link" href="javascript:;" @mouseenter="wxShow=true" @mouseleave="wxShow=false">
          <img src="/icons/wechat-ico@2x.png" alt="" class="ico">
          <span>订阅</span>
        </a>
        <router-link to="/history"  class="link" href="javascript:;">
          <img src="/icons/clock-ico@2x.png" alt="" class="ico">
          <span>观看历史</span>
        </router-link>
      </div>
      <a :href="webUrl" target="_blank" class="tjsl-btn">
        <img src="/icons/yezi-ico@2x.png" alt="" class="ico">
        提交收录
      </a>
      
      <transition name="fade">
        <div class="toast-app-wrapper" v-show="appShow">
          <img :src="loadAppImgBase64" alt="">
        </div>
      </transition>


      <transition name="fade">
        <div class="wx-wrapper" v-show="wxShow">
          <BaseWx scale="0.7"/>
        </div>
      </transition>
    </div>

  </div>
</template>
<script>
import BaseSearch from '@/components/base-search'
import {detailLoadapp} from '@/api/loadapp'
import BaseWx from '@/components/base-wx'

export default {
  components:{
    BaseSearch,
    BaseWx
  },
  data(){
    return{
      appShow:false,
      wxShow:false,
      loadAppImgBase64:''
    }
  },
  computed:{
    webUrl(){
      return this.$store.state.setting.webUrl
    },
    webLogo(){
      return this.$store.state.setting.webLogo
    }
  },
  methods:{
    appDownloadHandle(){
      this.$router.push('/app-download')
    },
    getLoadApp(){
      detailLoadapp().then((res)=>{
        this.loadAppImgBase64=res.data.img
      })
    }
  },
  mounted(){
    this.getLoadApp()
  }
}
</script>
<style scoped>
.header-wrapper{
  width:100%;
  background-color: #FFF;
  position: fixed;
  top:0px;
  left: 0px;
  z-index:1999;
}

.header{
  display: flex;
  align-items: center;
  height:70px;
  width:1200px;
  margin:0 auto;
  position: relative;
}

.logo{
  width:163.66px;
  height:35px;
}

.links{
  margin-right:18px;
  display: flex;
}

.links .link{
  display: flex;
  align-items: center;
  margin-right:20px;
  text-decoration: none;
}

.links .link span{
  color:#444;
}

.links .link .ico{
  width:28px;
  height:28px;
  display: block;
  margin-right:8px;
}

.tjsl-btn{
  width:113px;
  height:40px;
  line-height:40px;
  border-radius: 20px;
  text-align: center;
  background-color: #04CB94;
  text-decoration: none;
  display: block;
  color:#FFF;
}

.tjsl-btn .ico{
  width:20px;
  height:20px;
  margin-right:5px;
  vertical-align: middle;
}

.jz{
  margin-left: auto;
  margin-right:auto;
}

.toast-app-wrapper{
  position: absolute;
  top:75px;
  right:246px;
  z-index: 999;
}

.toast-app-wrapper>img{
  display: block;
}

.wx-wrapper {
  z-index:999;
  position: absolute;
  right:0px;
  top:75px;
}

</style>